/*
 * @Description: 3d模型数据
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-08-13 17:58:50
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:43
 */
import {
  MUTATIONS_PARK3D_FIRE_DEVICE_COUNT,
  MUTATIONS_PARK3D_FIRE_GROUP_COUNT,
  MUTATIONS_PARK3D_FIRE_DEVICE_LIST,
  MUTATIONS_PARK3D_FIRE_DEVICE_DISTRIBUTION,
  MUTATIONS_PARK3D_FIRE_ALARM_LIST,
  MUTATIONS_PARK3D_FIRE_LIST,
  MUTATIONS_PARK3D_FIRE_STAT_INFO
} from '../mutations_type'
const fireData = {
  state: () => {
    return {
      // 消防设备数
      fireDeviceCount: {
        deviceCount: 6,
        onlineDevice: 2,
        faultDevice: 4,
        warningDevice: 0,
        percentageOnline: '33.33%'
      },

      // 设备类型分布
      fireGroupCount: {
        groupList: [
          {
            key: '井下液位传感器',
            value: '1',
            memo: ''
          },
          {
            key: '水压力传感器',
            value: '1',
            memo: ''
          },
          {
            key: '恒星物联-液位传感器',
            value: '1',
            memo: ''
          },
          {
            key: '液位传感器',
            value: '1',
            memo: ''
          },
          {
            key: '无线压力变送器',
            value: '1',
            memo: ''
          },
          {
            key: '恒星物联-消防栓传感器',
            value: '1',
            memo: ''
          }
        ]
      },
      // 消防设备列表
      firDeviceList: [],

      // 消防设备分布
      fireDeviceDistribution: [],

      // 消防告警列表
      fireAlarmList: [],

      // 消防火警统计信息
      fireStatInfo: {
        numberToday: 0,
        numberMonth: 0,
        fireTrendsToday: [
          {
            abscissa: 1,
            ordinate: 0
          }
        ],
        fireTrendsMonth: [
          {
            abscissa: 1,
            ordinate: 0
          }
        ]
      },

      // 消防火警列表
      fireList: []
    }
  },
  mutations: {
    [MUTATIONS_PARK3D_FIRE_DEVICE_COUNT](state, val) {
      console.log('消防设备数', val)
      state.fireDeviceCount = val
    },

    [MUTATIONS_PARK3D_FIRE_GROUP_COUNT](state, val) {
      console.log('消防设备分类', val)
      state.fireGroupCount = val
    },

    [MUTATIONS_PARK3D_FIRE_DEVICE_LIST](state, val) {
      console.log('消防设备列表', val)
      state.firDeviceList = val
    },

    [MUTATIONS_PARK3D_FIRE_DEVICE_DISTRIBUTION](state, val) {
      console.log('消防设备分布', val)
      state.fireDeviceDistribution = val
    },

    [MUTATIONS_PARK3D_FIRE_ALARM_LIST](state, val) {
      console.log('消防告警列表', val)
      state.fireAlarmList = val
    },
    [MUTATIONS_PARK3D_FIRE_STAT_INFO](state, val) {
      console.log('消防火警统计信息', val)
      state.fireStatInfo = val
    },
    [MUTATIONS_PARK3D_FIRE_LIST](state, val) {
      console.log('消防火警列表', val)
      state.fireList = val
    }
  },
  actions: {
    // 消防设备数
    fn_api_get_park_fireDeviceCount({ commit }, data) {
      commit(MUTATIONS_PARK3D_FIRE_DEVICE_COUNT, data)
    },

    // 消防设备分布
    fn_api_get_park_fireGroupCount({ commit }, data) {
      commit(MUTATIONS_PARK3D_FIRE_GROUP_COUNT, data)
    },

    // 消防设备列表
    fn_api_get_park_fireDeviceList({ commit }, data) {
      commit(MUTATIONS_PARK3D_FIRE_DEVICE_LIST, data)
    },
    // 消防设备分布
    fn_api_get_park_fireDeviceDistribution({ commit }, data) {
      commit(MUTATIONS_PARK3D_FIRE_DEVICE_DISTRIBUTION, data)
    },
    // 消防告警列表
    fn_api_get_park_fireAlarmList({ commit }, data) {
      commit(MUTATIONS_PARK3D_FIRE_ALARM_LIST, data)
    },
    // 消防火警统计信息
    fn_api_get_park_fireStatInfo({ commit }, data) {
      commit(MUTATIONS_PARK3D_FIRE_STAT_INFO, data)
    },
    // 消防火警列表
    fn_api_get_park_fireList({ commit }, data) {
      commit(MUTATIONS_PARK3D_FIRE_LIST, data)
    }
  }
}
export default fireData
