/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-09-27 19:38:24
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:26
 */
import Storage from '@/util/storage'
import Website from '@/conf/website'
export default Storage(Website)
