import {
  fn_api__auth_token,
  fn_api__refresh_token,
  fn_api__logout,
  fn_api__get_info
} from '@/api/base'
import {
  MUTATIONS_USER__SET_TOKEN,
  MUTATIONS_USER__SET_REFRESHTOKEN,
  MUTATIONS_USER__SET_USERINFO,
  MUTATIONS_USER__REFRESHTOKEN,
  MUTATIONS_USER__CLEAR
} from '../mutations_type'
import router from '@/router'
import Token from '../util/token'
import Storage from '../util/storage'
import md5 from 'js-md5'
import { fn_store__format_routers, fn_util__deep_clone } from '../util/util'
const {
  fn_token__get,
  fn_refreshToken__get,
  fn_token__set,
  fn_parkKey__set,
  fn_refreshToken__set,
  fn_token__remove,
  fn_refreshToken__remove,
  fn_token__get_expires
} = Token
const { fn_storage__set, fn_storage__get, fn_storage__clear } = Storage

const user = {
  state: () => {
    return {
      token: fn_token__get() || '',
      refreshToken: fn_refreshToken__get() || '',
      userInfo: null,
      refreshTokenTimeout: fn_storage__get('refreshTokenTimeout') || 0
    }
  },
  mutations: {
    [MUTATIONS_USER__SET_TOKEN](state, val = '') {
      state.token = val
    },

    [MUTATIONS_USER__SET_REFRESHTOKEN](state, val = '') {
      state.refreshToken = val
    },

    [MUTATIONS_USER__SET_USERINFO](state, val = null) {
      state.userInfo = val
    },

    [MUTATIONS_USER__REFRESHTOKEN](state, val = 0) {
      state.refreshTokenTimeout = val
      fn_storage__set({ name: 'refreshTokenTimeout', content: val })
    },

    [MUTATIONS_USER__CLEAR](state) {
      state.token = ''
      state.refreshToken = ''
      state.userInfo = null
      state.refreshTokenTimeout = 0
    }
  },
  actions: {
    fn_a_user__handle_login({ commit, dispatch }, data) {
      // debugger;
      const postData = JSON.parse(JSON.stringify(data))
      postData.password = md5(postData.password)
      return new Promise((resolve, reject) => {
        fn_api__auth_token(postData)
          .then(res => {
            if (!(res && res.error)) {
              // debugger
              fn_storage__set({
                name: 'parkId',
                content: '1342755052921671681'
              })
              fn_parkKey__set('1342755052921671681', res.expires_in)
              fn_token__set(res.access_token, res.expires_in)
              fn_refreshToken__set(res.refresh_token, res.expires_in)
              commit(MUTATIONS_USER__SET_TOKEN, res.access_token || res.token)
              commit(MUTATIONS_USER__SET_REFRESHTOKEN, res.refresh_token)
              commit(MUTATIONS_USER__REFRESHTOKEN)
              dispatch('fn_a_user__handle_refresh_token', res.expires_in)
              Promise.all([dispatch('fn_a_user__get_info')])
                .then(() => {
                  resolve()
                })
                .catch(() => reject())
            } else {
              reject()
            }
          })
          .catch(() => reject())
      })
    },

    fn_a_user__handle_refresh_token({ commit, dispatch, state }, timeout) {
      if (!timeout) {
        const expires = new Date(fn_token__get_expires())
        if (!expires) return
        timeout = expires - new Date().getTime()
      }
      clearTimeout(state.refreshTokenTimeout)
      commit(
        MUTATIONS_USER__REFRESHTOKEN,
        // 实时刷新TOKEN
        setTimeout(() => {
          const tenantId =
            (state.userInfo && state.userInfo.tenantId) ||
            fn_storage__get('tenantCode')
          fn_api__refresh_token(state.refreshToken, tenantId)
            .then(res => {
              fn_token__set(res.access_token)
              fn_refreshToken__set(res.refresh_token)
              commit(MUTATIONS_USER__SET_TOKEN, res.access_token)
              commit(MUTATIONS_USER__SET_REFRESHTOKEN, res.refresh_token)
              dispatch('fn_a_user__handle_refresh_token', res.expires_in)
            })
            .catch(error => console.error(error))
        }, timeout - 60 * 1000)
      )
    },
    fn_a_user__handle_logout({ commit }) {
      return 
      return new Promise(resolve => {
        fn_api__logout()
          .then(async() => {
            fn_token__remove()
            fn_refreshToken__remove()
            // fn_parkKey__remove();
            fn_storage__clear()
            commit(MUTATIONS_USER__CLEAR)
            // commit(MUTATIONS_TAG__CLEARALL, null, { root: true });
            Socket.asyncDisconnect()
            router.push('/login').catch(error => error)
            resolve()
          })
          .catch(() => {
            fn_token__remove()
            fn_refreshToken__remove()
            // fn_parkKey__remove();
            fn_storage__clear()
            commit(MUTATIONS_USER__CLEAR)
            // commit(MUTATIONS_TAG__CLEARALL, null, { root: true });
            router.push('/login').catch(error => error)
            resolve()
          })
      })
    },
    fn_a_user__handle_parkInfo({ commit }) {
      // router.push('/login')
    },
    fn_a_user__get_info({ commit }) {
      return new Promise((resolve, reject) => {
        fn_api__get_info()
          .then(res => {
            const { data: userInfo } = res
            fn_storage__set({ name: 'tenantCode', content: userInfo.tenantId })
            commit(MUTATIONS_USER__SET_USERINFO, userInfo)
            resolve()
          })
          .catch(() => reject())
      })
    }
  }
}
export default user
