/*
 * @Description: 3d模型数据
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-08-13 17:58:50
 * @LastEditors: hs
 * @LastEditTime: 2021-10-15 13:53:37
 */
import {
  MUTATIONS_PARK3D__ZOOM,
  MUTATIONS_PARK3D__PREVIEWIMAGES,
  MUTATIONS_PARK3D__MONITOR_OWEBCONTROL,
  MUTATIONS_PARK3D__PARKING_INFO,
  MUTATIONS_PARK3D__PARK_INFO,
  MUTATIONS_PARK3D__PARK_DEVICE_DETAIL,
  MUTATIONS_PARK3D__PARK_PEOPLES_DETAIL,
  MUTATIONS_PARK3D__PARK_ENVIRON_DETAIL,
  MUTATIONS_PARK3D__PARK_ALARM_DETAIL,
  MUTATIONS_PARK3D__PARK_PARKING_DETAIL,
  MUTATIONS_PARK3D__PARK_ENERGY_DETAIL,
  MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_TREE_DATA,
  MUTATIONS_PARK3D__PARK_ALARMLIST,
  MUTATIONS_PARK3D__PARK_BUILDING_INFO,
  MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_INFO
} from '../mutations_type'
const parkData = {
  state: () => {
    return {
      previewImages: [],
      zoom: 1,
      oWebControl: null,

      // 园区首页数据
      parkInfo: {
        // 园区基本信息
        baseInfo: {
          area: 0, // 占地面积
          buildingNumber: 0, // 楼栋数量
          constructionArea: 0, // 建筑面积
          inenterprise: 0 // 入住企业
        },
        // 环境信息
        environmentInfo: {
          noise: '0',
          pm10: '0',
          pm25: '0',
          temperature: '0'
        },

        // 告警信息
        alarmInfo: {
          alarmNumberNew: 0, // 新增告警数
          alarmNumberHandled: 0, // 已处理告警数
          alarmNumberPending: 0 // 待处理告警数
        },

        // 能耗信息
        energyInfo: {
          powerConsumptionThisMonth: 0, // 本月耗电量
          powerConsumptionToday: 0, // 今日耗电量
          waterConsumptionThisMonth: 0, //	本月耗水量
          waterConsumptionToday: 0 //	今日耗水量
        },

        // 人员信息
        personInfo: {
          dayEnterNumber: 0, //	今日入园人次 String
          dubiousNumber: 0, // 今日可疑人次	string
          yesterDayNumber: 0, //	昨日入园人次	string
          visitorNumber: 0 //	访客人数	string
        },

        // 设备信息
        deviceInfo: {
          deviceCount: 0, // 设备总数	integer(int32)
          onlineDevice: 0, // 在线设备数	integer(int32)
          warningDevice: 0, // 离线设备数	integer(int32)
          faultDevice: 0 // 故障设备
        },

        // 告警列表
        alarmList: [],

        // 人员行为异常列表
        abnormalList: []
      },
      // 园区首页切换楼栋数据
      parkBuildingInfo: {
        // 园区基本信息
        baseInfo: {
          area: 0, // 占地面积
          buildingNumber: 0, // 楼栋数量
          constructionArea: 0, // 建筑面积
          inenterprise: 0 // 入住企业
        },
        // 选中的楼栋数据
        buildingInfo: {
          area: '0.00',
          belowArea: '230',
          companyNumber: 0,
          elevatorNumber: 8,
          floorNumber: 24,
          roomNumber: 0
        },
        // 环境信息
        environmentInfo: {
          noise: '0',
          pm10: '0',
          pm25: '0',
          temperature: '0'
        },

        // 告警信息
        alarmInfo: {
          alarmNumberNew: 0, // 新增告警数
          alarmNumberHandled: 0, // 已处理告警数
          alarmNumberPending: 0 // 待处理告警数
        },

        // 能耗信息
        energyInfo: {
          powerConsumptionThisMonth: 0, // 本月耗电量
          powerConsumptionToday: 0, // 今日耗电量
          waterConsumptionThisMonth: 0, //	本月耗水量
          waterConsumptionToday: 0 //	今日耗水量
        },

        // 人员信息
        personInfo: {
          dayEnterNumber: 0, //	今日入园人次 String
          dubiousNumber: 0, // 今日可疑人次	string
          yesterDayNumber: 0, //	昨日入园人次	string
          visitorNumber: 0 //	访客人数	string
        },

        // 设备信息
        deviceInfo: {
          deviceCount: 0, // 设备总数	integer(int32)
          onlineDevice: 0, // 在线设备数	integer(int32)
          warningDevice: 0, // 离线设备数	integer(int32)
          faultDevice: 0 // 故障设备
        },

        // 告警列表
        alarmList: [],

        // 人员行为异常列表
        abnormalList: []
      },
      // 园区首页切换楼层数据
      parkBuildingFloorInfo: {
        // 园区基本信息
        baseInfo: {
          area: 0, // 占地面积
          buildingNumber: 0, // 楼栋数量
          constructionArea: 0, // 建筑面积
          inenterprise: 0 // 入住企业
        },
        // 选中的楼栋数据
        buildingInfo: {
          area: '0.00',
          belowArea: '230',
          companyNumber: 0,
          elevatorNumber: 8,
          floorNumber: 24,
          roomNumber: 0
        },
        floorInfo: {
          area: '290',
          companyNumber: 0,
          roomNumber: 0
        },
        // 环境信息
        environmentInfo: {
          noise: '0',
          pm10: '0',
          pm25: '0',
          temperature: '0'
        },

        // 告警信息
        alarmInfo: {
          alarmNumberNew: 0, // 新增告警数
          alarmNumberHandled: 0, // 已处理告警数
          alarmNumberPending: 0 // 待处理告警数
        },

        // 能耗信息
        energyInfo: {
          powerConsumptionThisMonth: 0, // 本月耗电量
          powerConsumptionToday: 0, // 今日耗电量
          waterConsumptionThisMonth: 0, //	本月耗水量
          waterConsumptionToday: 0 //	今日耗水量
        },

        // 人员信息
        personInfo: {
          dayEnterNumber: 0, //	今日入园人次 String
          dubiousNumber: 0, // 今日可疑人次	string
          yesterDayNumber: 0, //	昨日入园人次	string
          visitorNumber: 0 //	访客人数	string
        },

        // 设备信息
        deviceInfo: {
          deviceCount: 0, // 设备总数	integer(int32)
          onlineDevice: 0, // 在线设备数	integer(int32)
          warningDevice: 0, // 离线设备数	integer(int32)
          faultDevice: 0 // 故障设备
        },

        // 告警列表
        alarmList: [],

        // 人员行为异常列表
        abnormalList: []
      },
      // 停车场信息
      parkingInfo: {
        fixedPlace: 0,
        freePlace: 0,
        groupCarTotal: 0,
        monthlyCardTotal: 0,
        nowGroupCarTotal: 0,
        nowMonthlyCardTotal: 0,
        nowTempCarTotal: 0,
        totalPlace: 0,
        usedPlace: 0
      },
      parkEnvironDetail: {
        newestWind: {
          ph: '0',
          windDirection: '0',
          windSpeed: '0'
        },
        Humidity: {}
      },

      parkDeviceDetail: {
        OnLineCount: {}, // 总设备数、在线设备数
        deviceDistribute: [], // 设备分布
        equipmentGroupNumber: [], // 设备分类及数量
        toDayInfo: [], // 设备故障信息-今日
        toDayInfoTrend: {
          faultList: [],
          offLineList: []
        }, // 设备故障趋势-今日
        toMonthInfo: [], // 设备故障信息-本月
        toMonthInfoTrend: {
          faultList: [],
          offLineList: []
        } // 设备故障趋势-本月
      }, // 首页-设备详情

      // 首页告警详情
      parkAlarmDetail: {
        alarmNumberNewToday: 0,
        alarmNumberHandledToday: 0,
        alarmNumberNewTrendsToday: [],
        alarmNumberHandledTrendsToday: [],
        alarmNumberNewThisMonth: 0,
        alarmNumberHandledThisMonth: 0,
        alarmNumberNewTrendsThisMonth: [],
        alarmNumberHandledTrendsThisMonth: [],
        totalByStatusToday: 0,
        alarmStatusDistributionToday: [],
        totalByStatusThisMonth: 0,
        alarmStatusDistributionThisMonth: [],
        totalByDeviceToday: 0,
        alarmDeviceDistributionToday: [],
        totalByDeviceThisMonth: 0,
        alarmDeviceDistributionThisMonth: [],
        totalByHandlingTimeToday: 0,
        alarmHandlingTimeDistributionToday: [],
        totalByHandlingTimeThisMonth: 0,
        alarmHandlingTimeDistributionThisMonth: []
      },
      // 停车场信息详情
      parkParkingDetail: {
        groupCarInThisMonth: 0, // 本月入场群组车次
        groupCarInToday: 0, // 今日入场群组车次
        groupCarOutThisMonth: 0, // 本月出场群组车次
        groupCarOutToday: 0, // 今日出场群组车次
        groupCarTotal: 0, // 群组车总数
        monthlyCardChargeThisMonth: 0, // 本月月卡收费
        monthlyCardChargeToday: 0, // 今日月卡收费
        monthlyCardChargeTrendsThisMonth: [
          {
            charge: 0, // 收费
            remark: '', // 时间轴备注
            timeScale: '' // 时间轴的时间刻度
          }
        ], //	本月月卡收费的时间趋势
        monthlyCardChargeTrendsToday: [
          {
            charge: 0, // 收费
            remark: '', // 时间轴备注
            timeScale: 0 // 时间轴的时间刻度
          }
        ], // 今日月卡收费的时间趋势
        monthlyCardInThisMonth: 0, // 本月入场月卡车次
        monthlyCardInToday: 0, // 今日入场月卡车次
        monthlyCardOutThisMonth: 0, // 本月出场月卡车次
        monthlyCardOutToday: 0, // 今日出场月卡车次
        monthlyCardTotal: 0, // 月卡总数
        nowGroupCarTotal: 0, // 目前停车场内停留的群组车数量
        nowMonthlyCardTotal: 0, // 目前停车场内停留的月卡车数量
        nowTempCarTotal: 0, // 目前停车场内停留的临时车数量
        tempCardInThisMonth: 0, // 本月入场临时车次
        tempCardInToday: 0, // 	今日入场临时车次
        tempCardOutThisMonth: 0, // 本月出场临时车次
        tempCardOutToday: 0, // 今日出场临时车次
        tempChargeThisMonth: 0, // 本月临停收费
        tempChargeToday: 0, // 今日临停收费
        tempChargeTrendsThisMonth: [
          {
            charge: 0, // 收费
            remark: '', // 	时间轴备注
            timeScale: 0 // 时间轴的时间刻度
          }
        ], // 本月临停收费的时间趋势
        tempChargeTrendsToday: [
          {
            charge: 0, // 收费
            remark: '', // 	时间轴备注
            timeScale: 0 // 时间轴的时间刻度
          }
        ], // 	今日临停收费的时间趋势
        totalChargeThisMonth: 0, // 本月总收费
        totalChargeToday: 0, // 今日总收费
        totalChargeTrendsThisMonth: [
          {
            charge: 0, // 收费
            remark: '', // 	时间轴备注
            timeScale: 0 // 时间轴的时间刻度
          }
        ], // 本月总收费的时间趋势
        totalChargeTrendsToday: [
          {
            charge: 0, // 收费
            remark: '', // 	时间轴备注
            timeScale: 0 // 时间轴的时间刻度
          }
        ], // 今日总收费的时间趋势
        totalInThisMonth: 0, // 本月入场总车次
        totalInToday: 0, // 今日入场总车次
        totalOutThisMonth: 0, // 本月出场总车次
        totalOutToday: 0, // 今日出场总车次
        vehiclesInThisMonth: [], // 本月入停车场的车次统计
        vehiclesInToday: [], // 今日入停车场的车次统计
        vehiclesOutThisMonth: [], // 本月出停车场的车次统计
        vehiclesOutToday: [] // 	今日出停车场的车次统计
      },
      // 首页能耗详情
      parkEnergyDetail: {
        averageDailyPowerConsumptionLastMonth: '',
        averageDailyWaterConsumptionLastMonth: '',
        dailyPowerConsumptionTrendsLastMonth: [],
        dailyPowerConsumptionTrendsThisMonth: [],
        dailyWaterConsumptionTrendsLastMonth: [],
        dailyWaterConsumptionTrendsThisMonth: [],
        powerConsumptionLastMonth: '',
        powerConsumptionThisMonth: '',
        powerConsumptionToday: '',
        powerConsumptionTrendsToday: [],
        timeAveragePowerConsumptionTrendsLastMonth: [],
        timeAverageWaterConsumptionTrendsLastMonth: [],
        topTenPowerVOThisMonth: [],
        topTenPowerVOToday: [],
        topTenWaterVOThisMonth: [],
        topTenWaterVOToday: [],
        waterConsumptionLastMonth: [],
        waterConsumptionThisMonth: [],
        waterConsumptionToday: [],
        waterConsumptionTrendsToday: []
      },
      // 停车场楼栋-楼层信息(树结构)
      parkTreeData: {
        type: 1,
        parentId: 0,
        name: '',
        mark: '',
        childrenVoList: []
      }
    }
  },
  mutations: {
    [MUTATIONS_PARK3D__PREVIEWIMAGES](state, val = []) {
      state.previewImages = val
    },
    [MUTATIONS_PARK3D__ZOOM](state, val = 1) {
      state.zoom = val
    },
    [MUTATIONS_PARK3D__MONITOR_OWEBCONTROL](state, val) {
      state.oWebControl = val
    },

    // 园区首页数据
    [MUTATIONS_PARK3D__PARK_INFO](state, val) {
      state.parkInfo = val
    },
    // 园区首页切换楼栋数据
    [MUTATIONS_PARK3D__PARK_BUILDING_INFO](state, val) {
      state.parkBuildingInfo = val
    },
    // 园区首页切换楼层数据
    [MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_INFO](state, val) {
      state.parkBuildingFloorInfo = val
    },
    // 停车场数据
    [MUTATIONS_PARK3D__PARKING_INFO](state, val) {
      // console.log('首页停车场数据', val)
      state.parkingInfo = val
    },

    [MUTATIONS_PARK3D__PARK_DEVICE_DETAIL](state, val) {
      // console.log('首页设备详情', val)
      state.parkDeviceDetail = val
    },

    [MUTATIONS_PARK3D__PARK_ENVIRON_DETAIL](state, val) {
      // console.log('首页环境详情', val)
      state.parkEnvironDetail = val
    },

    [MUTATIONS_PARK3D__PARK_PEOPLES_DETAIL](state, val) {
      // console.log('首页人员详情', val)
      state.parkPeoplesDetail = val
    },

    [MUTATIONS_PARK3D__PARK_ALARM_DETAIL](state, val) {
      // console.log('首页告警详情', val)
      state.parkAlarmDetail = val
    },
    [MUTATIONS_PARK3D__PARK_ENERGY_DETAIL](state, val) {
      // console.log('首页能耗详情', val)
      state.parkEnergyDetail = val
    },
    [MUTATIONS_PARK3D__PARK_PARKING_DETAIL](state, val) {
      // console.log('首页车辆信息详情', val)
      state.parkParkingDetail = val
    },
    [MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_TREE_DATA](state, val) {
      // console.log('3D模型-楼栋-楼层信息', val)
      state.parkTreeData = val
    },
    [MUTATIONS_PARK3D__PARK_ALARMLIST](state, val) {
      state.parkInfo.alarmList = val
    }
  },
  actions: {
    // 全局保存视频组件
    fn_save_oWebControl({ commit }, data) {
      commit(MUTATIONS_PARK3D__MONITOR_OWEBCONTROL, data)
    },

    // 首页数据
    fn_api_get_parkInfo({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_INFO, data)
    },
    // 园区首页切换楼栋数据
    fn_api_get_parkBuildingInfo({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_BUILDING_INFO, data)
    },
    // 园区首页切换楼层数据
    fn_api_get_parkBuildingFloorInfo({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_INFO, data)
    },
    // 停车场信息
    fn_api_get_parkingInfo({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARKING_INFO, data)
    },

    // 环境详情信息
    fn_api_get_park_environDetail({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_ENVIRON_DETAIL, data)
    },

    // 设备详情信息
    fn_api_get_park_deviceDetail({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_DEVICE_DETAIL, data)
    },

    // 人员详情信息
    fn_api_get_park_peoplesDetail({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_PEOPLES_DETAIL, data)
    },

    // 首页告警详情
    fn_api_get_park_alarmDetail({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_ALARM_DETAIL, data)
    },
    // 车辆详细信息
    fn_api_get_park_parkingDetail({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_PARKING_DETAIL, data)
    },
    // 首页能耗详情
    fn_api_get_park_energyDetail({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_ENERGY_DETAIL, data)
    },
    // 3D模型楼栋楼层数据
    fn_api__get_park_treeData({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_BUILDING_FLOOR_TREE_DATA, data)
    },
    fn_api__get_park_alarmList({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARK_ALARMLIST, data)
    }
  }
}
export default parkData
