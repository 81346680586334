/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-09-27 19:38:24
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:27
 */
import Token from '@/util/token'
import Website from '@/conf/website'
export default Token(Website)
